import * as React from 'react';

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { DETAILVIEW_TOOLBAR_HEIGHT, RESPONSIVE_DRAWER_HEIGHT } from '../../Constants';
import { DetailCard } from './DetailCard';
import { ApiLoadingGuard } from '../ApiLoadingGuard';
import DetailViewToolbar from './DetailViewToolbar';
import { ApiCallState, blobToDataURL } from '../../api';
import { DeliveryDTO, DeliveryService, PredictionDTO } from '../../api/generated';
import { ImageCard } from '../ImageCard';
import PredictionCard from '../PredictionCard';

export interface DetailViewProps {
  hideOverlay(): void;
  requestNextItem(): void;
  requestPreviousItem(): void;
  nextButtonEnabled: boolean;
  previousButtonEnabled: boolean;
  delivery: ApiCallState<DeliveryDTO>;
}

export function DetailView(props: DetailViewProps) {
  const [image, setImage] = React.useState<ApiCallState<string>>({});
  const [imageIdx, _] = React.useState<number>(0); // setImageIdx is not necessary YET because there's always only one image
  const [prediction, setPrediction] = React.useState<ApiCallState<PredictionDTO>>({ result: undefined });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const padding: string = '1rem';

  async function pictureDownload(): Promise<string | undefined> {
    try {
      if (!props.delivery?.result?.id) return '';
      const response = await DeliveryService.deliveryControllerGetDeliveryImage({
        path: {
          jobsiteId: props.delivery.result?.jobsiteId || '',
          deliveryId: props.delivery?.result?.id || '',
          imageId: props.delivery?.result?.pictureIds[imageIdx] || '',
        },
      });
      if (response.data) {
        return await blobToDataURL(response.data as Blob);
      }
    } catch (err) {
      throw err;
    }
  }

  React.useEffect(() => {
    (async () => {
      setImage({});
      if (props.delivery.result?.pictureIds.length !== 0) {
        return await pictureDownload();
      } else {
        setImage({ error: 'Kein Bild verfügbar' });
      }
    })()
      .then((img) => {
        if (!img) return;
        setImage({ result: img });
      })
      .catch((err) => {
        setImage({ error: `${err.message}` });
      });
  }, [props.delivery, imageIdx]);

  React.useEffect(() => {
    console.debug('useEffect listening to props.delivery.result');
    const noImagesFound = props?.delivery?.result?.pictureIds?.length === 0;
    if (noImagesFound) {
      setPrediction({ error: 'Keine Prognose verfügbar' });
      setImage({ error: 'Kein Bild verfügbar' });
      return;
    }
    (async () => {
      const response = await DeliveryService.deliveryControllerGetDeliveryPrediction({
        path: {
          deliveryId: props.delivery.result!!.id || '',
          jobsiteId: props.delivery.result!!.jobsiteId || '',
          imageId: props.delivery.result!!.pictureIds[imageIdx] || '',
        },
      });
      if (response.data) {
        const fetchedPrediction = response.data;
        if (fetchedPrediction === undefined || fetchedPrediction.output.length === 0) {
          setPrediction({ error: 'Keine Prognose verfügbar' });
          return;
        }
        setPrediction({ result: fetchedPrediction });
      } else if (response.error) {
        setPrediction({ error: `${response.error}` });
      }
    })();
  }, [props.delivery.result]);

  return (
    <ApiLoadingGuard apiStates={[props.delivery]}>
      <Paper
        sx={{
          width: '100%',
          position: 'absolute',
          overflowX: 'hidden', // Otherwise the user can scroll left and right on tablets
          overflowY: isSmallScreen ? 'scroll' : 'hidden',
        }}
      >
        <DetailViewToolbar
          nextButtonEnabled={props.nextButtonEnabled}
          previousButtonEnabled={props.previousButtonEnabled}
          toolbarTitle={props.delivery?.result?.delivery_id?.toString()}
          onCloseButtonPressed={props.hideOverlay}
          onNextButtonPressed={props.requestNextItem}
          onPreviousButtonPressed={props.requestPreviousItem}
        />
        {/* Css grid with ImageCard, PredictionCard and DataCard */}
        <Box
          style={{
            display: 'grid',
            gridAutoColumns: '1fr',
            gridTemplateColumns: isSmallScreen ? '1fr' : 'minmax(0, 1fr) minmax(0, 1fr)',
            gridTemplateRows: isSmallScreen ? 'repeat(3, minmax(0, 1fr))' : 'minmax(0, 1.5fr) minmax(0, 1fr)',
            gridColumnGap: '1rem',
            gridRowGap: '1rem',
            padding: padding,
            position: 'relative',
            justifyContent: 'stretch',
            alignContent: 'stretch',
            justifyItems: 'stretch',
            alignItems: 'stretch',
            width: `calc(100% - 2 * ${padding})`,
            // This height prop controls only the height of the inner grid.
            // We only subtract 2 * padding because the vertical padding between the cards is managed internally by the grid and can be ignored
            height: `calc(100vh - ${RESPONSIVE_DRAWER_HEIGHT}px - ${DETAILVIEW_TOOLBAR_HEIGHT}rem - 2 * ${padding})`,
          }}
        >
          {/* 'image-card' */}
          <Box style={{ gridArea: isSmallScreen ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 3' }}>
            <ImageCard
              image={image}
              showDownloadButton={true}
              downloadFileName={`${props.delivery.result?.delivery_id}_${imageIdx}.jpg`}
            />
          </Box>
          {/* 'prediction-card' */}
          <Box style={{ gridArea: '2 / 1 / 3 / 2' }}>
            <PredictionCard prediction={prediction} />
          </Box>
          {/* 'detail-card' */}
          <Box style={{ gridArea: isSmallScreen ? '3 / 1 / 4 / 2' : '2 / 2 / 3 / 3' }}>
            <DetailCard delivery={props.delivery} />
          </Box>
        </Box>
      </Paper>
    </ApiLoadingGuard>
  );
}
