import { ApiCallState } from '../api';
import { OutputSchemaDTO, PredictionDTO } from '../api/generated';
import {
  Box,
  Card,
  Container,
  Divider,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ApiLoadingGuard } from './ApiLoadingGuard';
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import styles from './PredictionCard.module.scss';
import { getGermanAVVClassNameFromAvvNumber } from '../Constants';

export const prediction_error_display = (): JSX.Element => (
  <Container sx={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
    <ErrorOutlineIcon fontSize='large' sx={{ margin: 'auto' }} />
    <Typography variant='h6' textAlign={'center'} sx={{ margin: 'auto' }}>
      Es konnte keine Prognose erstellt werden
    </Typography>
  </Container>
);

export interface PredictionCardProps {
  prediction: ApiCallState<PredictionDTO>;
}

const CONFIDENCE_COLORS = {
  HIGH: '#c4ffbe',
  LOW: '#fff0be',
  NONE: '#e6e6e6',
} as const;

const TooltipColorItem: React.FC<{ color: string; text: string }> = ({ color, text }) => (
  <Stack direction='row' spacing={1} alignItems={'center'}>
    <Card sx={{ height: '1.5rem', width: '1.5rem', backgroundColor: color, flexShrink: 0 }} />
    <Typography color='black'>{text}</Typography>
  </Stack>
);

const AVVFieldTooltipIcon: React.FC = () => (
  <Tooltip
    title={
      <Paper
        elevation={10}
        sx={{
          p: 2,
          bgcolor: 'common.white',
          color: 'common.black',
          borderRadius: '10px',
        }}
      >
        <Stack direction='column' spacing={2} sx={{ width: '220px' }}>
          <Typography variant='body1'>Prognose der AVV-Nummer anhand des aufgenommenen Bildes</Typography>
          <Divider />
          <TooltipColorItem color={CONFIDENCE_COLORS.HIGH} text='Sichere Prognose' />
          <TooltipColorItem color={CONFIDENCE_COLORS.LOW} text='Möglicherweise ungenau' />
          <TooltipColorItem color={CONFIDENCE_COLORS.NONE} text='Keine Zuweisung möglich' />
        </Stack>
      </Paper>
    }
    placement='top'
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'transparent', // Prevents the border of the paper component from bleeding trough
        },
      },
    }}
  >
    <InfoIcon
      sx={{
        position: 'absolute',
        right: 3,
        top: '1rem',
        transform: 'translateY(-50%)',
      }}
    />
  </Tooltip>
);

function getPredictionLogic(predictionOutput: OutputSchemaDTO[] | undefined) {
  if (!predictionOutput) return;

  const confidence_classes = {
    high: { range: [0.7, 1], color: CONFIDENCE_COLORS.HIGH },
    low: { range: [0.4, 0.7], color: CONFIDENCE_COLORS.LOW },
    none: { range: [0, 0.4], color: CONFIDENCE_COLORS.NONE },
  };

  const most_probable_class = predictionOutput.reduce((prev, current) =>
    prev.probability > current.probability ? prev : current
  );

  const confidence = Object.entries(confidence_classes).find(
    ([_, c]) => c.range[0] <= most_probable_class.probability && most_probable_class.probability <= c.range[1]
  );

  const isConfidenceNone: boolean = confidence?.[0] === 'none';

  const second_most_probable_class = predictionOutput.reduce((prev, current) =>
    prev.probability > current.probability && prev.probability !== most_probable_class.probability ? prev : current
  ) || { probability: 0 };

  const mostProbableClassGermanAVVTranslation: string | undefined = getGermanAVVClassNameFromAvvNumber(
    most_probable_class.avv,
    most_probable_class.class_name
  );
  const secondMostProbableClassGermanAVVTranslation: string | undefined = getGermanAVVClassNameFromAvvNumber(
    second_most_probable_class.avv,
    second_most_probable_class.class_name
  );

  return {
    confidenceColor: confidence?.[1].color,
    isConfidenceNone,
    mostProbableClassAVV: most_probable_class.avv,
    secondMostProbableClassAVV: second_most_probable_class.avv,
    mostProbableClassGermanAVVTranslation,
    secondMostProbableClassGermanAVVTranslation,
  };
}

const AVVNumberCardContent: React.FC<{
  avvNumber: string | undefined;
  class_name: string | undefined;
}> = ({ avvNumber, class_name }) => {
  return (
    <Box>
      <Typography align='center' variant='body1' sx={{ fontWeight: 700 }}>
        {avvNumber || ''}
      </Typography>
      <Typography align='center'>{class_name || ''}</Typography>
    </Box>
  );
};

const AVVField: React.FC<{
  predictionOutput: OutputSchemaDTO[] | undefined;
}> = ({ predictionOutput }) => {
  const [isAVVDropDownElementExpanded, setIsAVVDropDownElementExpanded] = React.useState(false);

  const {
    confidenceColor,
    isConfidenceNone,
    mostProbableClassAVV,
    secondMostProbableClassAVV,
    mostProbableClassGermanAVVTranslation,
    secondMostProbableClassGermanAVVTranslation,
  } = getPredictionLogic(predictionOutput) ?? {};

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
      <Stack direction='column' spacing={1} width='100%'>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
          <Typography variant='h6'>AVV</Typography>
          <AVVFieldTooltipIcon />
        </Stack>
        <Card
          sx={{
            borderRadius: '10px',
            backgroundColor: confidenceColor,
            width: '100%',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
          elevation={isConfidenceNone ? 0 : 10}
        >
          {isConfidenceNone ? (
            <>
              <Typography align='center' variant='inherit' sx={{ fontWeight: 700 }}>
                Keine eindeutige Zuweisung möglich
              </Typography>
            </>
          ) : (
            <AVVNumberCardContent avvNumber={mostProbableClassAVV} class_name={mostProbableClassGermanAVVTranslation} />
          )}
        </Card>
        {isConfidenceNone ? (
          <>
            <IconButton
              onClick={() => setIsAVVDropDownElementExpanded(!isAVVDropDownElementExpanded)}
              style={{ alignSelf: 'center' }}
            >
              {isAVVDropDownElementExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Grow in={isAVVDropDownElementExpanded}>
              <Stack spacing={1} alignItems='center'>
                <Card className={styles.small_avv_card} sx={{ backgroundColor: CONFIDENCE_COLORS.NONE }}>
                  <AVVNumberCardContent
                    avvNumber={mostProbableClassAVV}
                    class_name={mostProbableClassGermanAVVTranslation}
                  />
                </Card>
                <Card className={styles.small_avv_card} sx={{ backgroundColor: CONFIDENCE_COLORS.NONE }}>
                  <AVVNumberCardContent
                    avvNumber={secondMostProbableClassAVV}
                    class_name={secondMostProbableClassGermanAVVTranslation}
                  />
                </Card>
              </Stack>
            </Grow>
          </>
        ) : null}
      </Stack>
    </Box>
  );
};

const AttributesField: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    }}
  >
    <Stack direction='column' spacing={1} alignItems={'center'}>
      <Typography variant='h6'>Eigenschaften</Typography>
      {['Fremdkörper', 'Mutterboden', 'Kantenlänge'].map((elem) => (
        <Card
          key={elem}
          variant='outlined'
          sx={{
            p: 1,
            width: '100%',
            height: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography>{elem}</Typography>
        </Card>
      ))}
    </Stack>
  </Box>
);

const CategoryField: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <Stack direction='column' spacing={1}>
      <Stack direction='row' spacing={2} alignItems={'center'}>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          Sorte
        </Typography>
        <SettingsIcon
          sx={{
            position: 'absolute',
            right: 3,
            top: '1rem',
            transform: 'translateY(-50%)',
          }}
        />
      </Stack>
    </Stack>
  </Box>
);

const DisabledOverlay: React.FC = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 'calc(100% / 3)',
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
      backdropFilter: 'blur(2px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10,
    }}
  >
    <Typography
      variant='h5'
      color='white'
      sx={{
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      Bald verfügbar
    </Typography>
  </Box>
);

export default function PredictionCard(props: PredictionCardProps) {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 2rem)',
        position: 'relative',
      }}
      elevation={8}
    >
      <ApiLoadingGuard apiStates={[props.prediction]} error_display={prediction_error_display}>
        <Stack
          direction='row'
          divider={<Divider orientation='vertical' flexItem />}
          spacing={2}
          alignItems={'stretch'}
          justifyContent={'space-around'}
          height={'100%'}
        >
          <AVVField predictionOutput={props.prediction.result?.output} />
          <AttributesField />
          <CategoryField />
        </Stack>
        <DisabledOverlay />
      </ApiLoadingGuard>
    </Card>
  );
}
