import Box from '@mui/material/Box';
import { ResponsiveDrawer } from '../components/ResponsiveDrawer';
import { UserGrid } from '../components/Administration/UserGrid';

export function UserAdminPage() {
  return (
    <Box display={'flex'}>
      <ResponsiveDrawer />
      <Box
        sx={{
          paddingTop: '20px',
          paddingLeft: '1rem',
          height: '60vh',
          width: `calc(100% - 2rem)`,
          position: 'absolute',
          top: 64,
        }}
      >
        <UserGrid />
      </Box>
    </Box>
  );
}
